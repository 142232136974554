import { LitElement, TemplateResult, css, html } from 'lit';
import { Task } from '@lit/task';
import { consume } from '@lit/context';
import { customElement, property, state } from 'lit/decorators.js';

import '../../pli/pli-card';
import '../../pli/pli-tab-navigation';
import '../../pli/pli-button';
import '../../pli/pli-text';
import '../../pli/pli-status-label';
import '../../pli/pli-illustration';
import '../../pli/pli-skeleton';

import { baseStyles, gridStyles } from 'pli';
import { clientContext, type HttpClient } from 'context/client-context';
import { Status } from 'case-model';
import { PliStatusLabelVariant } from '../../pli/pli-status-label';
import { errorServiceContext } from 'context/error-service-context';
import { ErrorService } from 'error/error-service';
import { nameFormatter } from 'utils/name-formatter';
import { dateTimeFormatter } from 'utils/datetime-formatter';
import { defineHeaderItems } from 'pli/pli-table';
import './dashboard-empty-states';
import './dashboard-card-inner-layout';
import { urlParamsBuilder } from 'utils/url-params-builder';
import { DashboardConfigContext, dashboardConfigContext } from './context/dashboard-config-context';

type Case = {
    assigned: {
        firstName: string;
        lastName: string;
        userId: string;
    }[];
    caseId: number;
    created: string;
    customer: {
        customerId: string;
        name: string;
    };
    name: string;
    status: string;
};

interface CaseResponse {
    list: Case[];
    total: number;
}

@customElement('dashboard-cases-table')
class DashboardCasesTable extends LitElement {
    static styles = [baseStyles, gridStyles];

    @consume({ context: clientContext, subscribe: true })
    @property({ attribute: false })
    public client?: HttpClient;

    @consume({ context: errorServiceContext, subscribe: true })
    @property({ attribute: false })
    public errorService?: ErrorService;

    @consume({ context: dashboardConfigContext })
    dashboardConfig: DashboardConfigContext;

    @property()
    currentTab: CaseTab;

    get itemsVisibleCount() {
        return this.dashboardConfig.itemsVisibleCount;
    }

    private async _getMyCases(): Promise<CaseResponse> {
        const query = urlParamsBuilder({ page: 1, take: this.itemsVisibleCount });
        const response = await this.client.get(`users/cases?` + query);
        return (await response.json()) as CaseResponse;
    }

    private async _getLatestCases(): Promise<CaseResponse> {
        const statuses: Status[] = [Status.Investigating, Status.Reporting];
        const query = urlParamsBuilder({ statuses, page: 1, take: this.itemsVisibleCount });
        const response = await this.client.get(`cases?` + query);
        return (await response.json()) as CaseResponse;
    }

    private _casesTask = new Task(this, {
        task: async ([currentTab]) => {
            switch (currentTab) {
                case 'My Cases':
                    return await this._getMyCases();
                case 'Latest Cases':
                    return await this._getLatestCases();
                default:
                    return null;
            }
        },
        args: () => [this.currentTab],
    });

    _createCaseURL({ customer, caseId }: Case): string {
        return `customers/${customer.customerId}/cases/${caseId}/#investigations`;
    }

    _formatName(input: Case) {
        if (!input.customer || !input.customer.name) return '';
        return input.customer.name;
    }

    headerItems = defineHeaderItems({
        Id: {
            sortField: null,
            columnSpan: 1,
        },
        Name: {
            sortField: null,
            columnSpan: 3,
        },
        Customer: {
            sortField: null,
            columnSpan: 3,
        },
        Status: {
            sortField: null,
            columnSpan: 2,
        },
        Created: {
            sortField: null,
            columnSpan: 3,
        },
    });

    _successLayout(data: CaseResponse) {
        if (!data.list.length) {
            return html`<empty-state-cases></empty-state-cases>`;
        }

        const mapped = data.list.map((i) => ({
            ...i,
            status: i.status as PliStatusLabelVariant,
            url: this._createCaseURL(i),
            formattedName: this._formatName(i),
            formattedDate: dateTimeFormatter(i.created),
        }));

        const renderTemplate = (c: (typeof mapped)[0]): TemplateResult => html`
            <tr>
                <td>
                    <strong>${c.caseId}</strong>
                </td>
                <td>
                    <a data-link="navigate" .href="${c.url}">${c.name}</a>
                </td>
                <td>${c.formattedName}</td>
                <td>
                    <pli-status-label variant="${c.status}">${c.status}</pli-status-label>
                </td>
                <td>${c.formattedDate}</td>
            </tr>
        `;

        return html`
            <pli-table
                .headerItems="${this.headerItems}"
                .items="${mapped}"
                .renderTemplate="${renderTemplate}"
            ></pli-table>
        `;
    }

    render() {
        return html`
            ${this._casesTask.render({
                pending: () =>
                    html`<pli-table
                        .headerItems="${this.headerItems}"
                        .loading="${true}"
                        .loadingRowsCount="${6}"
                    ></pli-table>`,
                complete: (data) => this._successLayout(data),
                error: (e: Error) => this.errorService.showErrorDialog(e.message),
            })}
        `;
    }
}

const availableTabs = ['My Cases', 'Latest Cases'] as const;
type CaseTab = (typeof availableTabs)[number];

@customElement('dashboard-cases')
class DashboardAlerts extends LitElement {
    @state()
    private _currentTab: CaseTab = availableTabs[0];

    static styles = [baseStyles];

    private _tabs = availableTabs;

    private _setCurrentTab(tab: CaseTab) {
        this._currentTab = tab;
    }

    render() {
        return html`
            <pli-card>
                <dashboard-card-inner-layout>
                    <pli-tab-navigation
                        .items="${this._tabs}"
                        @navigate="${(e) => this._setCurrentTab(e.detail.current)}"
                    ></pli-tab-navigation>
                    <dashboard-cases-table .currentTab="${this._currentTab}"></dashboard-cases-table>
                </dashboard-card-inner-layout>
            </pli-card>
        `;
    }
}

import { LitElement, TemplateResult, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

import '../../pli/pli-card';
import '../../pli/pli-tab-navigation';
import '../../pli/pli-button';
import '../../pli/pli-text';
import '../../pli/pli-status-label';
import '../../pli/pli-illustration';
import '../../pli/pli-skeleton';

import { baseStyles, gridStyles } from 'pli';
import { Task } from '@lit/task';
import { consume } from '@lit/context';
import { clientContext, type HttpClient } from 'context/client-context';
import { AlertStatus } from 'alert-status';
import { PliStatusLabelVariant } from '../../pli/pli-status-label';
import { errorServiceContext } from 'context/error-service-context';
import { ErrorService } from 'error/error-service';
import { nameFormatter } from 'utils/name-formatter';
import { dateTimeFormatter } from 'utils/datetime-formatter';
import { defineHeaderItems } from 'pli/pli-table';

import './dashboard-card-inner-layout';
import './dashboard-empty-states';
import { urlParamsBuilder } from 'utils/url-params-builder';
import { DashboardConfigContext, dashboardConfigContext } from './context/dashboard-config-context';

type Alert = {
    alertId: number;
    assigned: {
        firstName: string;
        lastName: string;
        userId: string;
    }[];
    created: string;
    customerId: string;
    customer: {
        customerId: string;
        identification: null;
        name: string;
        alerts: {
            alertId: number;
            created: string;
            customerId: string;
            customer: null;
            caseId: number;
            case: null;
            rule: {
                ruleId: string;
                name: string;
                history: {
                    alerts: number;
                    falsePositive: number;
                };
            };
            auditTrails: null;
        }[];
    };
    rule: {
        name: string;
        ruleId: string;
    };
    status: string;
};

interface AlertResponse {
    list: Alert[];
    total: number;
}

@customElement('dashboard-alerts-table')
class DashboardAlertsTable extends LitElement {
    static styles = [baseStyles, gridStyles];

    @consume({ context: clientContext, subscribe: true })
    @property({ attribute: false })
    public client?: HttpClient;

    @consume({ context: errorServiceContext, subscribe: true })
    @property({ attribute: false })
    public errorService?: ErrorService;

    @consume({ context: dashboardConfigContext })
    dashboardConfig: DashboardConfigContext;

    @property()
    currentTab: AlertTab;

    get itemsVisibleCount() {
        return this.dashboardConfig.itemsVisibleCount;
    }

    private async _getMyAlerts(): Promise<AlertResponse> {
        const query = urlParamsBuilder({ page: 1, take: this.itemsVisibleCount });
        const response = await this.client.get(`users/alerts?` + query);
        return (await response.json()) as AlertResponse;
    }

    private async _getLatestAlerts(): Promise<AlertResponse> {
        const statuses: AlertStatus[] = [AlertStatus.Escalated, AlertStatus.New, AlertStatus.Opened];
        const query = urlParamsBuilder({ status: statuses, page: 1, take: this.itemsVisibleCount });
        const result = await this.client.get('alerts?' + query);
        return (await result.json()) as AlertResponse;
    }

    private _alertsTask = new Task(this, {
        task: async ([currentTab]) => {
            switch (currentTab) {
                case 'My Alerts':
                    return await this._getMyAlerts();
                case 'Latest Alerts':
                    return await this._getLatestAlerts();
                default:
                    return null;
            }
        },
        args: () => [this.currentTab],
    });

    _createRuleURL({ customerId, alertId }: Partial<Alert>): string {
        return `customers/${customerId}/alerts/${alertId}/#investigations`;
    }

    _formatDate(date: string) {
        return dateTimeFormatter(date);
    }

    _formatName(customer: Alert['customer']) {
        if (!customer || !customer.name) return '';
        return customer.name;
    }

    headerItems = defineHeaderItems({
        Id: {
            sortField: null,
            columnSpan: 1,
        },
        Rule: {
            sortField: null,
            columnSpan: 3,
        },
        Customer: {
            sortField: null,
            columnSpan: 3,
        },
        Status: {
            sortField: null,
            columnSpan: 2,
        },
        Created: {
            sortField: null,
            columnSpan: 3,
        },
    });

    _successLayout(data: AlertResponse) {
        if (!data.list.length) {
            return html`<empty-state-alerts></empty-state-alerts>`;
        }

        const mapped = data.list.map((i) => ({
            ...i,
            status: i.status as PliStatusLabelVariant,
            url: this._createRuleURL({ customerId: i.customer.customerId, alertId: i.alertId }),
            nameFormatted: this._formatName(i.customer),
            dateFormatted: this._formatDate(i.created),
        }));

        const renderTemplate = (alert: (typeof mapped)[0]): TemplateResult => html`
            <tr>
                <td>
                    <strong>${alert.alertId}</strong>
                </td>
                <td>
                    <a data-link="navigate" .href="${alert.url}">${alert.rule.name}</a>
                </td>
                <td>${alert.nameFormatted}</td>
                <td>
                    <pli-status-label variant="${alert.status}">${alert.status}</pli-status-label>
                </td>
                <td>${alert.dateFormatted}</td>
            </tr>
        `;

        return html`
            <pli-table
                .headerItems="${this.headerItems}"
                .items="${mapped}"
                .renderTemplate="${renderTemplate}"
            ></pli-table>
        `;
    }

    render() {
        return html`
            ${this._alertsTask.render({
                pending: () =>
                    html`<pli-table
                        .headerItems="${this.headerItems}"
                        .loading="${true}"
                        .loadingRowsCount="${6}"
                    ></pli-table>`,
                complete: (data) => this._successLayout(data),
                error: (e: Error) => this.errorService.showErrorDialog(e.message),
            })}
        `;
    }
}

const availableTabs = ['My Alerts', 'Latest Alerts'] as const;
type AlertTab = (typeof availableTabs)[number];

@customElement('dashboard-alerts')
class DashboardAlerts extends LitElement {
    @state()
    private _currentTab: AlertTab = availableTabs[0];

    static styles = [baseStyles];

    private _tabs = availableTabs;

    private _setCurrentTab(tab: AlertTab) {
        this._currentTab = tab;
    }

    render() {
        return html`
            <pli-card>
                <dashboard-card-inner-layout>
                    <pli-tab-navigation
                        .items="${this._tabs}"
                        @navigate="${(e) => this._setCurrentTab(e.detail.current)}"
                    ></pli-tab-navigation>
                    <dashboard-alerts-table .currentTab="${this._currentTab}"></dashboard-alerts-table>
                </dashboard-card-inner-layout>
            </pli-card>
        `;
    }
}
